export default class Validator {
    constructor(parent) {
        this.parent = parent;
        this.setComponentGetValidateValues = this.setComponentGetValidateValues.bind(this);
        this.setComponentOnValidated = this.setComponentOnValidated.bind(this);
        this.attachChild = this.attachChild.bind(this);
        this.validate = this.validate.bind(this);
        this.finishedValidation = this.finishedValidation.bind(this);
        this.validResult = this.validResult.bind(this);
    }

    setComponentGetValidateValues(componentGetValidateValues) {
        this.componentGetValidateValues = componentGetValidateValues;
    }

    setComponentOnValidated(componentOnValidated) {
        this.componentOnValidated = componentOnValidated;
    }
    
    componentHasMounted() {
        if (this.parent) {
            this.parent.attachChild(this);
        }
    }

    componentHasUnmounted() {
        if (this.parent) {
            this.parent.removeChild(this);
        }
    }
    
    attachChild(child) {
        if (child && child instanceof Validator) {
            this.children = this.children || [];
            if (!(child in this.children)) {
                this.children.push(child);
            }
        }
    }

    removeChild(child) {
        if (child && child instanceof Validator && this.children) {
            let index = this.children.indexOf( child );
            if (index > -1) {
                this.children.splice(index, 1);
            }
        }
    }

    validate(value) {
        return this.finishedValidation({value, error: null});
    }

    finishedValidation(results) {
        if (this.componentOnValidated) {
            this.componentOnValidated(results);
        }
        return results;
    }

    validResult(rootResult=[]) {
        var result = rootResult;
        if (this.componentGetValidateValues) {
            let {id, value} = this.componentGetValidateValues();
            if (id) {
                let {...rest} = this.validate(value)
                rootResult.push({id, ...rest});
            }
        }
        if (this.children) {
            this.children.forEach(child => {
                child.validResult(result);
            });
        }
        return result;
    }
}

export class EmailValidator extends Validator {
    validate(value) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let error = !re.test(String(value).toLowerCase().trim());
        return this.finishedValidation({value: value, error: error ? 'Please fill in a correct email address' : null});
    }
}

export class ValueSetValidator extends Validator {
    validate(value) {
        return this.finishedValidation({value: value, error: !value ? 'Please fill in a value.' : null});
    }
}

export class NumberValidator extends Validator {
   
    constructor(parent, minValue, maxValue, zeroPadding=2, description='number') {
        super(parent);
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.zeroPadding = zeroPadding;
        this.description = description;
    }

    validate(value) {
        let check1 = (value) => value;
        let check2 = (value) => !isNaN(value);
        let check3 = (value) => this.minValue ? Number(value) >= this.minValue : true;
        let check4 = (value) => this.maxValue ? Number(value) <= this.maxValue : true;
        let allChecks = check1(value)&&check2(value)&&check3(value)&&check4(value);
        if (allChecks) { 
            var val = value+'';
            while(val.length < this.zeroPadding) val = '0' + val;
            return this.finishedValidation({value: val, error: null});
        }
        else {
            return this.finishedValidation({value: value, error: 'Please fill in a valid ' + this.description + '.'});
        }
    }
    
}

export class PhoneNumberValidator extends Validator {
    validate(value) {
        let re = /^[+#*()[\]]*([0-9][ ext+-pw#*()[\]]*){6,45}$/;
        let error = !re.test(String(value).toLocaleUpperCase());
        return this.finishedValidation({value: value, error: error ? 'Please fill in a correct phone number (include area code).' : null});
    }
}

export class SelectValidator extends Validator {
    validate(value) {
        let error = value === '';
        return this.finishedValidation({value: value, error: error ? 'Please select a ticket type.' : null});
    }
}